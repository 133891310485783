<template lang="pug">
#personnal
    .head
        p.imgbox
            img(:src="detail.head_img_url")
            input.upload_input(
                type="file",
                accept="image/*",
                @change="getFile"
            )
        p.name {{ detail.username }}
    .per-box 
        p 昵称
        input(:placeholder="detail.username", v-model="form.username")
        //- p {{ detail.username }}
    .per-box 
        p 手机
        input(:placeholder="detail.mobile", v-model="form.mobile")
        //- p {{ detail.mobile }}
    .per-enter(@click="submitForm") 保存
</template>

<style src="./index.styl" lang="stylus"></style>
<style src="../../stylus/reset.styl" lang="stylus"></style>


 

<script>
import { userDetail, upload, userSave } from "../../config/request";
import ShareImpl from '@/weixinApi.js';

export default {
    data() {
        return {
            detail: [],

            form: {
                head_img: "",
                username: "",
                mobile: "",
            },
        };
    },
    components: {},
    async created() {
        this.getDatail();
    },
    methods: {
        getDatail() {
            userDetail().then((res) => {
                if (res.code === 20000) {
                    this.detail = res.data;
                    this.form = res.data;
                    let shareUrl = window.location.href;
                    // if(shareUrl.indexOf('from') != -1){
                    //   shareUrl = window.location.href.split("?")[0];
                    // }
                    // window.console.log(url + 'favicon.png');
                    console.log(res,11111);
                    let img = window.location.href.split("#")[0] + 'd.jpg';
                    let shareTitle = '链上汽车';
                    let shareDesc = '个人资料';
                    console.log(shareDesc,'分享title')
                    let shareImg =  img;
                    // let shareImg = img;
                    console.log(shareImg,'图片111')
                    console.log(img,'本地111')
                    ShareImpl({
                      shareTitle,
                      shareDesc,
                      shareUrl: location.origin + location.pathname + "?share=true/" + location.hash,
                      shareImg
                    });
                }
            });
        },

        getFile(e) {
            this.file = e.target.files;
            let formData = new FormData();
            formData.append("file", this.file[0]);
            upload(formData).then((res) => {
                if (res.code === 20000) {
                    this.detail.head_img_url = res.data.head_img_url;
                    this.form.head_img = res.data.head_img;
                }
            });
        },

        submitForm() {
            console.log("保存");
            let data = {
                head_img: this.form.head_img,
                username: this.form.username,
                mobile: this.form.mobile,
            };

            userSave(data).then((res) => {
                if (res.code === 20000) {
                    this.toast({ message: res.data });
                }
            });
        },
    },
};
</script>
<style lang="stylus" scoped></style>